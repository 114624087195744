/* Chatbot.css */

.chatbot-container {
  width: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  background-color: #87CEFA;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.chatbot-messages {
  height: 400px;
  overflow-y: scroll;
  padding: 8px;
}
.message {
  margin: 8px 0;
  padding: 8px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5;
}
.user-message {
  background-color: #007bff;
  color: #fff;
  text-align: right;
}
.header-display {
  background-color: #FFD700;
  color: #000000;
  text-align: center;
}
.ai-message {
  background-color: #f0f0f0;
  text-align: left;
}
.chatbot-input-form {
  display: flex;
  margin-top: 16px;
}
.chatbot-input-form input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
}
.chatbot-input-form button {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 0 4px 4px 0;
  font-size: 16px;
  cursor: pointer;
}
.chatbot-input-form button:hover {
  background-color: #0056b3;
}
.img {
   width: 10px;
   height: 10px;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
